import { ParseResult } from 'papaparse';
import { includes, uniq } from 'lodash-es';
import { PanelistPoolSource } from '../../../common/enums';

export function filterAndConcatenateCsvParseResult(result: ParseResult<string>): string[] {
  return uniq(result.data.map((row) => [...row].join(',')).filter((el) => !!el.trim()));
}

export const externalIdSources = [
  PanelistPoolSource.Liveramp,
  PanelistPoolSource.MobileAdId,
  PanelistPoolSource.PythonId,
  PanelistPoolSource.OpinionRoute,
];

export function isExternalPoolSource(poolSource: PanelistPoolSource) {
  return includes(externalIdSources, poolSource);
}
