import { IHttpPromise } from 'angular';
import { Literal, Record, Union, String, Static, Array, Number, Null, Boolean, Dictionary, Optional } from 'runtypes';
import { urlService } from './url.service';
import {
  DraftChannel,
  ExcludedRespondentStatus,
  PanelistPoolSource,
  PanelistPoolType,
  QuotaOptions,
  SupplySource,
  TargetGender,
  ExcludeStatusTimeline,
} from '../enums';
import { ProjectSettings } from '../project-settings.service';
import { TargetGroupModel } from '../models/target-group.model';
import { TargetGroupMapper } from '../target-group/target-group-mapper';
import { AboveZero, GuidString, Iso8601UtcString, NonEmptyString, NonNegative } from '../../custom-runtypes';
import { StorageDraft } from '../target-group/target-group.repository';
import { WeightingStrategy } from '../models/quotas.model';
import { BucketKeyResponse } from './quota-responses';
import { $http } from '../../ngimport';
import { AvailabilityResultResponse, CreatePanelistPoolResponse } from './supply.httpservice';
import { CompressedHttpRequest } from '../runtime-type-validating-http-provider.decorator';
import { AsyncReportStatus, ProjectSettingsResponse } from './project.httpservice';
import { globalDebugOptions } from '../global-debug-options';

export const DraftInfoResponse = Record({
  id: AboveZero,
  name: NonEmptyString,
});
export type DraftInfoResponse = Static<typeof DraftInfoResponse>;

export const DraftsResponse = Array(
  Record({
    id: AboveZero,
    channel: Union(
      Literal(DraftChannel.Access),
      Literal(DraftChannel.AccessPro),
      Literal(DraftChannel.ManagedServices)
    ),
    name: NonEmptyString,
    owner: NonEmptyString,
    created: Iso8601UtcString,
    updated: Iso8601UtcString,
  })
);
export type DraftsResponse = Static<typeof DraftsResponse>;

export const ProjectTemplateQuotaOption = Union(
  Literal(QuotaOptions.None),
  Literal(QuotaOptions.Completes),
  Literal(QuotaOptions.Custom)
);
export type ProjectTemplateQuotaOption = Static<typeof ProjectTemplateQuotaOption>;

export const ProjectTemplateSupplyMix = Record({
  supplyGroups: Array(
    Record({
      id: AboveZero,
      name: NonEmptyString,
      panelIds: Array(AboveZero),
      percentage: NonNegative,
      wantedCompletes: NonNegative.Or(Null),
      wantedStarts: NonNegative.Or(Null),
      source: Union(
        Literal(SupplySource.CintPanels),
        Literal(SupplySource.OwnPanels),
        Literal(SupplySource.PrivatePricing)
      ),
      customCpi: AboveZero.Or(Null),
    })
  ),
});
export type ProjectTemplateSupplyMix = Static<typeof ProjectTemplateSupplyMix>;

export const LinksData = Record({
  liveLinkTemplate: String.Or(Null),
  testLinkTemplate: String.Or(Null),
  screenoutInformation: String.Or(Null),
});

export type LinksData = Static<typeof LinksData>;

export const RespondentStatusTimeline = Record({
  respondentStatus: Union(
    Literal(ExcludedRespondentStatus.ScreenedOut),
    Literal(ExcludedRespondentStatus.Complete),
    Literal(ExcludedRespondentStatus.QuotaFull),
    Literal(ExcludedRespondentStatus.SurveyClosed),
    Literal(ExcludedRespondentStatus.TimedOut),
    Literal(ExcludedRespondentStatus.Started),
    Literal(ExcludedRespondentStatus.QualityTerminate)
  ),
  timeline: Union(
    Literal(ExcludeStatusTimeline.TwelveMonths),
    Literal(ExcludeStatusTimeline.SixMonths),
    Literal(ExcludeStatusTimeline.ThreeMonths),
    Literal(ExcludeStatusTimeline.OneMonth),
    Literal(ExcludeStatusTimeline.TwoWeeks)
  ),
});

export const TargetGroupValidationResult = Record({
  deprecatedRegions: Array(String),
  hasDeprecatedPostalCodes: Boolean,
  hasDeprecatedProfiling: Boolean,
  hasDeprecatedExcludedProjects: Boolean,
  hasDeprecatedPanelistPoolSource: Boolean,
  hasInclusivePanelistPoolFromApi: Boolean,
  hasExclusivePanelistPoolFromApi: Boolean,
  hasDeletedExcludedProjects: Boolean,
  hasDeprecatedQuotas: Boolean,
  visiblePanels: Array(String),
  unavailablePanels: Array(String),
  hasPanelsFromMultipleSupplySources: Boolean,
  hasDeprecatedAdHocSupplier: Boolean,
  hasIncompatiblePanels: Boolean,
  embargoedRegions: Array(String),
  hasEmbargoedPostalCodes: Boolean,
});

export type TargetGroupValidationResult = Static<typeof TargetGroupValidationResult>;

export const ProjectTemplateTargetGroup = Record({
  replacesTargetGroupId: Number.Or(Null),
  replacesProjectIds: Array(Number).Or(Null),
  name: NonEmptyString,
  countryId: Number.Or(Null),
  country: String.Or(Null),
  gender: Union(Literal(TargetGender.Both), Literal(TargetGender.Male), Literal(TargetGender.Female)),
  minAge: NonNegative.Or(Null),
  maxAge: NonNegative.Or(Null),
  wantedNumberOfCompletes: NonNegative.Or(Null),
  wantedNumberOfStarts: NonNegative.Or(Null),
  estimatedIncidenceRate: NonNegative,
  estimatedLengthOfInterview: NonNegative,
  startDate: Iso8601UtcString,
  numberOfDaysInField: AboveZero,
  useFixedLoi: Boolean,

  regions: Record({
    regionTypeId: Number.Or(Null),
    regionList: Array(
      Record({
        regionId: AboveZero,
        regionName: Null,
      })
    ),
    uploadedPostalCodes: Array(String).Or(Null),
    mainRegionsAutomaticallySelected: Boolean,
  }),

  profiling: Record({
    panelSpecificProfiling: Boolean,
    selectedVariables: Dictionary(
      Record({
        categoryId: AboveZero.Or(Null),
      }),
      'number'
    ),
  }),

  panels: Record({
    supplySource: Union(
      Literal(SupplySource.SystemSelected),
      Literal(SupplySource.UnspecifiedPanels),
      Literal(SupplySource.AdHoc),
      Literal(SupplySource.SupplyMix),
      Literal(SupplySource.PanelistPool)
    ),
    ownPanelCurrency: String.Or(Null),
    selectedPrivatePricingRateCardHash: String.Or(Null),
    selectedPanels: Array(
      Record({
        id: AboveZero,
        name: Null,
      })
    ),
    adHocSupplier: Record({
      id: NonNegative,
      languageId: NonNegative,
    }).Or(Null),
    panelistPool: Record({
      source: Union(
        Literal(PanelistPoolSource.None),
        Literal(PanelistPoolSource.AccessProject),
        Literal(PanelistPoolSource.ConnectCampaign),
        Literal(PanelistPoolSource.ConnectCampaignPool),
        Literal(PanelistPoolSource.Liveramp),
        Literal(PanelistPoolSource.MobileAdId),
        Literal(PanelistPoolSource.PythonId),
        Literal(PanelistPoolSource.OpinionRoute),
        Literal(PanelistPoolSource.StatSocial)
      ),
      type: Union(
        Literal(PanelistPoolType.None),
        Literal(PanelistPoolType.Inclusive),
        Literal(PanelistPoolType.Exclusive)
      ),
      panelistCount: NonNegative, // always hardcoded to 0 further on
      importedPanelistIds: String.Or(Null),
      selectedGroup: Union(Literal(''), Literal('opinionHub'), Literal('ownPanels'), Literal('privatePricing')).Or(
        Null
      ),
      rootPoolId: GuidString,
      selectedPoolId: GuidString,
      panelIds: Array(AboveZero).Or(Null),
      hasAvailablePanelists: Boolean,
      hasAnyLockedPanels: Boolean,
      sourceUrl: String.Or(Null),
      createPoolResult: Optional(CreatePanelistPoolResponse),
      availabilityResult: Optional(AvailabilityResultResponse),
    }),
    useCustomCpi: Boolean,
    customCpi: Number.Or(Null),
    supplyMix: ProjectTemplateSupplyMix,
  }),

  quotas: Record({
    quotas: Array(
      Record({
        matchId: AboveZero,
        name: NonEmptyString,
        keys: Array(String),
        quotaOption: ProjectTemplateQuotaOption,
        wantedCompletes: NonNegative.Or(Null),
        wantedStarts: NonNegative.Or(Null),
        initialFeasible: NonNegative.Or(Null), // unimportant for drafts
        actual: NonNegative, // unimportant for drafts
        buckets: Array(AboveZero),
      })
    ),
    buckets: Array(
      Record({
        id: AboveZero,
        name: NonEmptyString,
        key: BucketKeyResponse,
      })
    ),
    weightingStrategy: Union(Literal(WeightingStrategy.EvenDistribution), Literal(WeightingStrategy.RimWeighting)),
  }),

  excludeProjects: Record({
    projects: Array(
      Record({
        id: AboveZero,
        name: Null,
        respondentStatusTimelines: Optional(Array(RespondentStatusTimeline)),
      })
    ),
    respondentStatusTimelines: Array(RespondentStatusTimeline),
  }),

  incentives: Record({
    fixedIncentiveAmount: NonNegative.Or(Null),
  }),

  validation: TargetGroupValidationResult,

  linksData: Optional(LinksData),
  surveyMetadata: Optional(
    Record({
      studyTypes: Array(String),
    })
  ),
});
export type ProjectTemplateTargetGroup = Static<typeof ProjectTemplateTargetGroup>;

export const DraftResponse = Record({
  id: AboveZero,
  channel: Union(Literal(DraftChannel.Access), Literal(DraftChannel.AccessPro), Literal(DraftChannel.ManagedServices)),
  name: NonEmptyString,
  owner: NonEmptyString,
  created: Iso8601UtcString,
  updated: Iso8601UtcString,
  projectSettings: Optional(ProjectSettingsResponse),
  targetGroups: Array(ProjectTemplateTargetGroup),
});
export type DraftResponse = Static<typeof DraftResponse>;

export const DraftFromPoolResponse = Record({
  resource: NonEmptyString,
  id: Number,
});
export type DraftFromPoolResponse = Static<typeof DraftFromPoolResponse>;

export class DraftHttpService {
  createDraft(
    draftName: string,
    targetGroups: TargetGroupModel[],
    projectSettings: ProjectSettings
  ): IHttpPromise<DraftInfoResponse> {
    const data = {
      draftName,
      targetGroups: TargetGroupMapper.toDraft(targetGroups),
      projectSettings,
    };
    if (globalDebugOptions.options.disableCompressRequests) {
      return $http.validatingPost(DraftInfoResponse, urlService.createDraft(), data);
    }
    return this.createDraftCompressed(data);
  }

  createDraftFromPool(
    draftName: string,
    panelistPoolId: GuidString,
    wantedCompletes: number,
    countryId: number
  ): IHttpPromise<DraftFromPoolResponse> {
    const data = {
      draftName,
      panelistPoolId,
      wantedCompletes,
      countryId,
    };
    return $http.validatingPost(DraftFromPoolResponse, urlService.createDraftFromPanelistPool(), data);
  }

  updateDraft(
    storageDraft: StorageDraft,
    targetGroups: TargetGroupModel[],
    projectSettings: ProjectSettings,
    updatedName?: string
  ): IHttpPromise<DraftInfoResponse> {
    const data = {
      draftName: updatedName || storageDraft.name,
      targetGroups: TargetGroupMapper.toDraft(targetGroups),
      projectSettings,
    };
    if (globalDebugOptions.options.disableCompressRequests) {
      return $http.validatingPut(DraftInfoResponse, urlService.updateDraft(storageDraft.id), data);
    }
    return this.updateDraftCompressed(data, storageDraft.id);
  }

  getAllDrafts(): IHttpPromise<DraftsResponse> {
    return $http.validatingGet(DraftsResponse, urlService.getAllDrafts());
  }

  deleteDraft(id: number): IHttpPromise<void> {
    return $http.delete(urlService.deleteDraft(id));
  }

  loadDraft(id: number): IHttpPromise<DraftResponse> {
    return $http.validatingGet(DraftResponse, urlService.loadDraft(id));
  }

  getDraftSnapshotReportStatus(id: number): IHttpPromise<AsyncReportStatus> {
    return $http.get(urlService.draftSnapshotReportStatus(id));
  }

  getDraftSnapshotReportFile(id: number): IHttpPromise<string> {
    return $http.get(urlService.draftSnapshotReport(id));
  }

  generateDraftSnapshotReport(projectId: number): IHttpPromise<AsyncReportStatus> {
    return $http.post(urlService.draftSnapshotReport(projectId), {});
  }

  private updateDraftCompressed(payload: any, id: number): IHttpPromise<DraftInfoResponse> {
    const data: CompressedHttpRequest = {
      payload,
      compressRequest: true,
    };
    return $http.validatingPutGzip(DraftInfoResponse, urlService.updateDraftCompressed(id), data);
  }

  private createDraftCompressed(payload: any): IHttpPromise<DraftInfoResponse> {
    const data: CompressedHttpRequest = {
      payload,
      compressRequest: true,
    };
    return $http.validatingPostGzip(DraftInfoResponse, urlService.createDraftCompressed(), data);
  }
}

export const draftHttpService = new DraftHttpService();
