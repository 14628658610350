import { IHttpPromise } from 'angular';
import { Literal, Record, Union, String, Static, Array, Number, Null, Boolean, Dictionary, Optional } from 'runtypes';
import { urlService } from './url.service';
import {
  PanelistPoolSource,
  PanelistPoolType,
  QuotaOptions,
  SupplySource,
  TargetGender,
  TemplateChannel,
} from '../enums';
import { TargetGroupModel } from '../models/target-group.model';
import { TargetGroupMapper } from '../target-group/target-group-mapper';
import { AboveZero, GuidString, Iso8601UtcString, NonEmptyString, NonNegative } from '../../custom-runtypes';
import { WeightingStrategy } from '../models/quotas.model';
import { BucketKeyResponse } from './quota-responses';
import { $http } from '../../ngimport';

import { ProjectSettings } from '../project-settings.service';
import { RespondentStatusTimeline, TargetGroupValidationResult } from './draft.httpservice';
import { AvailabilityResultResponse, CreatePanelistPoolResponse } from './supply.httpservice';
import { ProjectSettingsResponse } from './project.httpservice';

export const TemplateInfoResponse = Record({
  id: AboveZero,
  name: NonEmptyString,
});
export type TemplateInfoResponse = Static<typeof TemplateInfoResponse>;

const UpdateHistoryItemRecord = Record({
  userName: NonEmptyString,
  updated: Iso8601UtcString,
});

export const TemplatesResponse = Array(
  Record({
    id: AboveZero,
    countryId: AboveZero,
    channel: Union(
      Literal(TemplateChannel.Access),
      Literal(TemplateChannel.AccessPro),
      Literal(TemplateChannel.ManagedServices)
    ),
    name: NonEmptyString,
    owner: NonEmptyString,
    created: Iso8601UtcString,
    updated: Iso8601UtcString,
    updateHistory: Array(UpdateHistoryItemRecord),
  })
);

export type TemplateHistoryItem = Static<typeof UpdateHistoryItemRecord>;

export type TemplatesResponse = Static<typeof TemplatesResponse>;

export const ProjectTemplateQuotaOption = Union(
  Literal(QuotaOptions.None),
  Literal(QuotaOptions.Completes),
  Literal(QuotaOptions.Custom)
);
export type ProjectTemplateQuotaOption = Static<typeof ProjectTemplateQuotaOption>;

export const ProjectTemplateSupplyMix = Record({
  supplyGroups: Array(
    Record({
      id: AboveZero,
      name: NonEmptyString,
      panelIds: Array(AboveZero),
      percentage: NonNegative,
      wantedCompletes: NonNegative.Or(Null),
      wantedStarts: NonNegative.Or(Null),
      source: Union(
        Literal(SupplySource.CintPanels),
        Literal(SupplySource.OwnPanels),
        Literal(SupplySource.PrivatePricing)
      ),
      customCpi: AboveZero.Or(Null),
    })
  ),
});
export type ProjectTemplateSupplyMix = Static<typeof ProjectTemplateSupplyMix>;

export const ProjectTemplateTargetGroup = Record({
  replacesTargetGroupId: Number.Or(Null),
  replacesProjectIds: Array(Number).Or(Null),
  name: NonEmptyString,
  countryId: Number.Or(Null),
  country: String.Or(Null),
  gender: Union(Literal(TargetGender.Both), Literal(TargetGender.Male), Literal(TargetGender.Female)),
  minAge: NonNegative.Or(Null),
  maxAge: NonNegative.Or(Null),
  wantedNumberOfCompletes: NonNegative.Or(Null),
  wantedNumberOfStarts: NonNegative.Or(Null),
  estimatedIncidenceRate: NonNegative,
  estimatedLengthOfInterview: NonNegative,
  startDate: Iso8601UtcString,
  numberOfDaysInField: AboveZero,
  useFixedLoi: Boolean,

  regions: Record({
    regionTypeId: Number.Or(Null),
    regionList: Array(
      Record({
        regionId: AboveZero,
        regionName: Null,
      })
    ),
    uploadedPostalCodes: Array(String).Or(Null),
    mainRegionsAutomaticallySelected: Boolean,
  }),

  profiling: Record({
    panelSpecificProfiling: Boolean,
    selectedVariables: Dictionary(
      Record({
        categoryId: AboveZero.Or(Null),
      }),
      'number'
    ),
  }),

  panels: Record({
    supplySource: Union(
      Literal(SupplySource.SystemSelected),
      Literal(SupplySource.UnspecifiedPanels),
      Literal(SupplySource.AdHoc),
      Literal(SupplySource.SupplyMix),
      Literal(SupplySource.PanelistPool)
    ),
    ownPanelCurrency: String.Or(Null),
    selectedPrivatePricingRateCardHash: String.Or(Null),
    selectedPanels: Array(
      Record({
        id: AboveZero,
        name: Null,
        tags: Optional(Array(NonEmptyString)),
      })
    ),
    adHocSupplier: Record({
      id: NonNegative,
      languageId: NonNegative,
    }).Or(Null),
    panelistPool: Record({
      source: Union(
        Literal(PanelistPoolSource.None),
        Literal(PanelistPoolSource.AccessProject),
        Literal(PanelistPoolSource.ConnectCampaign),
        Literal(PanelistPoolSource.Liveramp),
        Literal(PanelistPoolSource.MobileAdId),
        Literal(PanelistPoolSource.PythonId),
        Literal(PanelistPoolSource.OpinionRoute)
      ),
      type: Union(
        Literal(PanelistPoolType.None),
        Literal(PanelistPoolType.Inclusive),
        Literal(PanelistPoolType.Exclusive)
      ),
      panelistCount: NonNegative, // always hardcoded to 0 further on
      importedPanelistIds: String.Or(Null),
      selectedGroup: Union(Literal(''), Literal('opinionHub'), Literal('ownPanels'), Literal('privatePricing')).Or(
        Null
      ),
      rootPoolId: GuidString,
      selectedPoolId: GuidString,
      panelIds: Array(AboveZero).Or(Null),
      hasAvailablePanelists: Boolean,
      hasAnyLockedPanels: Boolean,
      sourceUrl: String.Or(Null),
      createPoolResult: Optional(CreatePanelistPoolResponse),
      availabilityResult: Optional(AvailabilityResultResponse),
    }),
    customCpi: Number.Or(Null),
    useCustomCpi: Boolean,
    supplyMix: ProjectTemplateSupplyMix,
  }),

  quotas: Record({
    quotas: Array(
      Record({
        matchId: AboveZero,
        name: NonEmptyString,
        keys: Array(String),
        quotaOption: ProjectTemplateQuotaOption,
        wantedCompletes: NonNegative.Or(Null),
        wantedStarts: NonNegative.Or(Null),
        initialFeasible: NonNegative.Or(Null),
        actual: NonNegative,
        buckets: Array(AboveZero),
        customCpi: AboveZero.Or(Null),
      })
    ),
    buckets: Array(
      Record({
        id: AboveZero,
        name: NonEmptyString,
        key: BucketKeyResponse,
      })
    ),
    weightingStrategy: Union(Literal(WeightingStrategy.EvenDistribution), Literal(WeightingStrategy.RimWeighting)),
  }),

  excludeProjects: Record({
    projects: Array(
      Record({
        id: AboveZero,
        name: Null,
        respondentStatusTimelines: Array(RespondentStatusTimeline),
      })
    ),
    respondentStatusTimelines: Array(RespondentStatusTimeline),
  }),

  incentives: Record({
    fixedIncentiveAmount: NonNegative.Or(Null),
  }),

  validation: TargetGroupValidationResult,
  surveyMetadata: Optional(
    Record({
      studyTypes: Array(String),
    })
  ),
});
export type ProjectTemplateTargetGroup = Static<typeof ProjectTemplateTargetGroup>;

export const ListTemplateResponse = Record({
  id: AboveZero,
  countryId: AboveZero,
  channel: Union(
    Literal(TemplateChannel.Access),
    Literal(TemplateChannel.AccessPro),
    Literal(TemplateChannel.ManagedServices)
  ),
  name: NonEmptyString,
  owner: NonEmptyString,
  created: Iso8601UtcString,
  updated: Iso8601UtcString,
  updateHistory: Array(UpdateHistoryItemRecord),
});
export type ListTemplateResponse = Static<typeof ListTemplateResponse>;

export const LoadTemplateResponse = Record({
  id: AboveZero,
  countryId: AboveZero,
  channel: Union(
    Literal(TemplateChannel.Access),
    Literal(TemplateChannel.AccessPro),
    Literal(TemplateChannel.ManagedServices)
  ),
  name: NonEmptyString,
  owner: NonEmptyString,
  created: Iso8601UtcString,
  updated: Iso8601UtcString,
  updateHistory: Array(UpdateHistoryItemRecord),
  targetGroup: ProjectTemplateTargetGroup,
  projectSettings: Optional(ProjectSettingsResponse),
});

export type LoadTemplateResponse = Static<typeof LoadTemplateResponse>;

export const TemplateValidationResult = Record({
  isValid: Boolean,
  hasInvalidSupplyMix: Boolean,
  hasInvalidSingleSourceSupply: Boolean,
  hasInvalidPanelSpecificProfiling: Boolean,
  hasInvalidProfilingQuotas: Boolean,
  incompatiblePanels: Array(String),
});

export type TemplateValidationResult = Static<typeof TemplateValidationResult>;

export const LoadAndValidateTemplateResponse = Record({
  template: LoadTemplateResponse,
  validation: TemplateValidationResult,
});

export type LoadAndValidateTemplateResponse = Static<typeof LoadAndValidateTemplateResponse>;

export class TemplateHttpService {
  createTemplate(targetGroup: TargetGroupModel, projectSettings: ProjectSettings): IHttpPromise<TemplateInfoResponse> {
    const data = {
      targetGroup: TargetGroupMapper.toTargetGroup(targetGroup),
      projectSettings,
    };

    return $http.validatingPost(TemplateInfoResponse, urlService.createTemplate(), data);
  }

  updateTemplateTargetGroup(
    targetGroup: TargetGroupModel,
    projectSettings: ProjectSettings
  ): IHttpPromise<TemplateInfoResponse> {
    const data = {
      targetGroup: TargetGroupMapper.toTargetGroup(targetGroup),
      projectSettings,
    };
    return $http.validatingPut(
      TemplateInfoResponse,
      urlService.updateTemplate(targetGroup.projectTemplateSource.templateId),
      data
    );
  }

  getAllTemplates(countryId?: number): IHttpPromise<TemplatesResponse> {
    return $http.validatingGet(TemplatesResponse, urlService.getAllTemplates(countryId));
  }

  deleteTemplate(id: number): IHttpPromise<void> {
    return $http.delete(urlService.deleteTemplate(id));
  }

  loadTemplate(id: number): IHttpPromise<LoadTemplateResponse> {
    return $http.validatingGet(LoadTemplateResponse, urlService.loadTemplate(id));
  }

  loadAndValidateTemplate(id: number, projectSettings: ProjectSettings): IHttpPromise<LoadAndValidateTemplateResponse> {
    return $http.validatingPost(
      LoadAndValidateTemplateResponse,
      urlService.loadAndValidateTemplate(id),
      projectSettings
    );
  }

  getTemplateCountries(): IHttpPromise<number[]> {
    return $http.get(urlService.getTemplateCountries());
  }
}

export const templateHttpService = new TemplateHttpService();
