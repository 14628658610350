import { includes, isEmpty } from 'lodash-es';
import { ExistingTargetGroup } from './http-services/existing-project.models';
import { EligiblePanelSourceKeys } from '../target-groups/active-target-group/models/active-supply.model';

export enum InitiatingAction {
  Welcome = 'welcome',
  LoadDraft = 'loadDraft',
  LoadTemplate = 'loadTemplate',
  EditTemplate = 'editTemplate',
  CreateNewProjectTemplate = 'createNewProjectTemplate',
  LoadTargetGroups = 'loadTargetGroups',
  DeleteAllTargetGroups = 'deleteAllTargetGroups',
  CreateTargetGroup = 'createTargetGroup',
  DuplicateTargetGroup = 'duplicateTargetGroup',
  ChangeActiveTargetGroup = 'changeActiveTargetGroup',
  DeleteTargetGroup = 'deleteTargetGroup',
  ReuseTargetGroups = 'reuseTargetGroups',
  AddNewTargetGroup = 'addNewTargetGroup',
  ModifyBasicSettings = 'modifyBasicSettings',
  ProjectOverview = 'projectOverview',
  ManageProject = 'manageProject',
  ContinueWithUnsaved = 'continueWithUnsaved',
  ReplaceTargetGroups = 'replaceTargetGroups',
}

export enum LinkSource {
  None = 'none',
  LinkTemplate = 'linkTemplate',
  LinkPool = 'linkPool',
  UniqueLinkPool = 'uniqueLinkPool',
}

export function linkSourceDuplicateValidation(linkSource: LinkSource): string {
  switch (linkSource) {
    case LinkSource.None:
      return 'The target group does not have any survey links';
    case LinkSource.UniqueLinkPool:
    case LinkSource.LinkPool:
      return 'Unique survey links cannot be copied to a new target group';
    case LinkSource.LinkTemplate:
    default:
      return undefined;
  }
}

export enum QuotaOptions {
  None = 'none',
  Completes = 'completes', // census on completes
  Custom = 'custom',
}

export enum PanelistPoolType {
  None = 'none',
  Inclusive = 'inclusive',
  Exclusive = 'exclusive',
}

export enum DraftChannel {
  Access = 'access',
  AccessPro = 'accessPro',
  ManagedServices = 'managedServices',
}

export enum TemplateChannel {
  Access = 'access',
  AccessPro = 'accessPro',
  ManagedServices = 'managedServices',
}

export enum QuotaPresetSaveOutcome {
  Success = 'success',
  NameExists = 'nameExists',
  CensusNonProfilingPresetExists = 'censusNonProfilingPresetExists',
  CensusProfilingPresetExists = 'censusProfilingPresetExists',
  UnknownError = 'unknownError',
}

export enum TargetGender {
  Both = 'both',
  Male = 'male',
  Female = 'female',
}

export enum TargetingType {
  Gender = 'gender',
  Age = 'age',
  Region = 'region',
  Profiling = 'profiling',
}

export function parseTargetGender(str: string): TargetGender {
  switch (str) {
    case 'male':
      return TargetGender.Male;
    case 'female':
      return TargetGender.Female;
    case 'both':
      return TargetGender.Both;
    default:
      return undefined;
    // default: throw new Error(`could not parse TargetGender from value [${str}]`);
  }
}

export enum QuotaPresetLoadFailureReason {
  SupplySetupIncorrectForPanelProfiling = 'supplySetupIncorrectForPanelProfiling',
  SupplySetupIncorrectForGlobalProfiling = 'supplySetupIncorrectForGlobalProfiling',
}

export enum SupplySource {
  SystemSelected = 'systemSelected',
  CintPanels = 'cintPanels',
  OwnPanels = 'ownPanels',
  PrivatePricing = 'privatePricing',
  AdHoc = 'adHoc',
  PanelistPool = 'panelistPool',
  SupplyMix = 'supplyMix',
  UnspecifiedPanels = 'unspecifiedPanels',
}

export enum CreateOrUpdateAdHocSupplierError {
  GeneralError = 'general',
  AlreadyExists = 'supplierAlreadyExists',
}

export enum ProjectChannel {
  Unknown = 'unknown',
  Access = 'access',
  Test = 'test',
  AccessPro = 'accessPro',
  ManagedServices = 'managedServices',
  Api = 'api',
  Track = 'track',
}

export function hasInclusivePanelistPool(targetGroup: ExistingTargetGroup): boolean {
  return targetGroup.supply.hasPanelistPool && targetGroup.supply.panelistPool.type === PanelistPoolType.Inclusive;
}

export function getSupplySource(targetGroup: ExistingTargetGroup): SupplySource {
  if (targetGroup.adHocSupplier) return SupplySource.AdHoc;
  if (hasInclusivePanelistPool(targetGroup)) return SupplySource.PanelistPool;
  if (isEmpty(targetGroup.supply.selectedPanels)) return SupplySource.SystemSelected;
  if (
    includes(
      targetGroup.quotas.quotas.map((q) => q.quotaGrouping).reduce((a, b) => a.concat(b), []),
      QuotaGrouping.Supply
    )
  )
    return SupplySource.SupplyMix;
  return null; // some kind of panels (Cint|Own|PrivatePricing)
}

export enum HttpsStatusKey {
  Unknown = 'unknown',
  Valid = 'valid',
  ValidNotUsed = 'validNotUsed',
  InvalidCertificate = 'invalidCertificate',
  Unsupported = 'unsupported',
}

export enum RegionTypeKey {
  Main = 'main',
  Generic = 'generic',
  PostalCodes = 'postal-codes',
}

export enum ExcludedRespondentStatus {
  None = 'none',
  ScreenedOut = 'screenedOut',
  Complete = 'complete',
  QuotaFull = 'quotaFull',
  SurveyClosed = 'surveyClosed',
  TimedOut = 'timedOut',
  Started = 'started',
  QualityTerminate = 'qualityTerminate',
}

export enum ExcludeStatusTimeline {
  None = 'none',
  TwelveMonths = 'twelveMonths',
  SixMonths = 'sixMonths',
  ThreeMonths = 'threeMonths',
  OneMonth = 'oneMonth',
  TwoWeeks = 'twoWeeks',
}

export enum TemplatesListType {
  ManageTemplates = 'manageTemplates',
  UseTemplate = 'useTemplate',
}

export function getExcludeStatusTimelineDescription(value: ExcludeStatusTimeline): string {
  switch (value) {
    case ExcludeStatusTimeline.None:
      return 'N/A';
    case ExcludeStatusTimeline.TwelveMonths:
      return '12 months';
    case ExcludeStatusTimeline.SixMonths:
      return '6 months';
    case ExcludeStatusTimeline.ThreeMonths:
      return '3 months';
    case ExcludeStatusTimeline.OneMonth:
      return '1 month';
    case ExcludeStatusTimeline.TwoWeeks:
      return '2 weeks';
    default:
      return 'N/A';
  }
}

export function parseExcludeStatusWithTimeline(value: string): ExcludeStatusTimeline {
  switch (value) {
    case 'twelveMonths':
      return ExcludeStatusTimeline.TwelveMonths;
    case 'sixMonths':
      return ExcludeStatusTimeline.SixMonths;
    case 'threeMonths':
      return ExcludeStatusTimeline.ThreeMonths;
    case 'oneMonth':
      return ExcludeStatusTimeline.OneMonth;
    case 'twoWeeks':
      return ExcludeStatusTimeline.TwoWeeks;
    default:
      return ExcludeStatusTimeline.None;
  }
}

export function getExcludedRespondentStatusDescription(value: ExcludedRespondentStatus): string {
  switch (value) {
    case ExcludedRespondentStatus.ScreenedOut:
      return 'Screened out';
    case ExcludedRespondentStatus.Complete:
      return 'Complete';
    case ExcludedRespondentStatus.QuotaFull:
      return 'Quota full';
    case ExcludedRespondentStatus.SurveyClosed:
      return 'Survey closed';
    case ExcludedRespondentStatus.TimedOut:
      return 'Timed out';
    case ExcludedRespondentStatus.QualityTerminate:
      return 'Quality terminate';
    case ExcludedRespondentStatus.Started:
      return 'Started';
    default:
      return '';
  }
}

export function parseExcludedRespondentStatus(value: string): ExcludedRespondentStatus {
  switch (value) {
    case 'screenedOut':
      return ExcludedRespondentStatus.ScreenedOut;
    case 'complete':
      return ExcludedRespondentStatus.Complete;
    case 'quotaFull':
      return ExcludedRespondentStatus.QuotaFull;
    case 'surveyClosed':
      return ExcludedRespondentStatus.SurveyClosed;
    case 'timedOut':
      return ExcludedRespondentStatus.TimedOut;
    case 'started':
      return ExcludedRespondentStatus.Started;
    case 'qualityTerminate':
      return ExcludedRespondentStatus.QualityTerminate;
    default:
      return ExcludedRespondentStatus.None;
  }
}

export enum ProfilingOption {
  Global = 'global',
  Panel = 'panel',
}

export enum CircularType {
  Initial = 0,
  Reminder = 1,
}

export enum PanelistPoolSource {
  None = 'none',
  AccessProject = 'accessProject',
  ConnectCampaign = 'connectCampaign',
  ConnectCampaignPool = 'connectCampaignPool',
  PythonId = 'pythonUserId',
  MobileAdId = 'deviceId', // AKA Device Identifiers
  Liveramp = 'liveramp',
  OpinionRoute = 'opinionRoute',
  StatSocial = 'statSocial',
}

export enum TargetGroupPromptMode {
  None = 'none',
  ShowWithLockedPanels = 'showWithLockedPanels',
  RemoveLockedPanels = 'removeLockedPanels',
}

export enum UpdateRespondentStage {
  Prepare = 'prepare',
  Confirm = 'confirm',
  Error = 'error',
  NoGroups = 'no-groups',
}

export enum RespondentStatusNumeral {
  Unanswered = 0,
  Started = 1,
  Declined = 2,
  EarlyScreenout = 3,
  LateScreenout = 4,
  Complete = 5,
  QuotaFull = 6,
  Bounced = 7,
  SurveyClosed = 8,
  TimedOut = 9,
  Other = 10,
  Suspicious = 11,
  BadProjectToken = 12,
  PossibleDuplicate = 13,
  Routed = 14,
  EndOfRouter = 15,
  Fraudulent = 16,
  QualityTerminate = 17,
  PreScreenStarted = 18,
  PreScreenTerminate = 19,
  PreScreenTimedOut = 20,
  TargetableScreenout = 21,
  ProfileTerminated = 22,
  RelevantIdDuplicate = 23,
  RelevantIdBadGeoip = 24,
  RelevantIdFraudulent = 25,
  RelevantIdFailure = 26,
  BadGeoip = 27,
  TechnicalTerminate = 28,
  Unsubscribed = 29,
}

export enum RespondentStatusTextual {
  Unanswered = 'unanswered',
  Started = 'started',
  Declined = 'declined',
  EarlyScreenout = 'earlyScreenout',
  LateScreenout = 'lateScreenout',
  Complete = 'complete',
  QuotaFull = 'quotaFull',
  Bounced = 'bounced',
  SurveyClosed = 'surveyClosed',
  TimedOut = 'timedOut',
  Other = 'other',
  Suspicious = 'suspicious',
  BadProjectToken = 'badProjectToken',
  PossibleDuplicate = 'possibleDuplicate',
  Routed = 'routed',
  EndOfRouter = 'endOfRouter',
  Fraudulent = 'fraudulent',
  QualityTerminate = 'qualityTerminate',
  PreScreenStarted = 'preScreenStarted',
  PreScreenTerminate = 'preScreenTerminate',
  PreScreenTimedOut = 'preScreenTimedOut',
  TargetableScreenout = 'targetableScreenout',
  ProfileTerminated = 'profileTerminated',
  RelevantIdDuplicate = 'relevantIdDuplicate',
  RelevantIdBadGeoip = 'relevantIdBadGeoip',
  RelevantIdFraudulent = 'relevantIdFraudulent',
  RelevantIdFailure = 'relevantIdFailure',
  BadGeoip = 'badGeoip',
  TechnicalTerminate = 'technicalTerminate',
  Unsubscribed = 'unsubscribed',
}

export enum TargetGroupStatus {
  Pending = 'pending',
  Activated = 'activated',
  Paused = 'paused',
  Closed = 'closed',
}

export enum QuotaStatus {
  NotStarted = 'notStarted',
  Active = 'active',
  Paused = 'paused',
  Closed = 'closed',
}

export enum QuotaFulfillmentType {
  QuotasOnCompletes = 'quotasOnCompletes',
  QuotasOnStarted = 'quotasOnStarted',
}

export enum TargetGroupType {
  Existing = 'existing',
  New = 'new',
}

// NOTE: flags enum on the backend, so `Age | Gender` will be serialized as `["age", "gender"]`
export enum QuotaGrouping {
  Ungrouped = 'ungrouped',
  Age = 'age',
  Gender = 'gender',
  Region = 'region',
  Supply = 'supply',
  Profiling = 'profiling',
}

export enum Product {
  Access = 'access',
  AccessPro = 'access-pro',
  ManagedServices = 'managed-services',
}

export enum ProductMode {
  Access = 'access',
  AccessPro = 'accessPro',
  ManagedServices = 'managedServices',
}

export enum AutoDeliveryStrategy {
  None = 'none',
  SystemConvenient = 'systemConvenient',
  SpreadCompletesOverTime = 'spreadCompletesOverTime',
  CustomSpread = 'customSpread',
  Asap = 'asap',
}

export enum WeightingResult {
  None = 'none',
  Failure = 'failure',
  Success = 'success',
  Approximated = 'approximated',
}

// NOTE: flags enum on the backend, so `Ir | Loi` will be serialized as `["ir", "loi"]`
export enum DisqualifiedPanelReason {
  None = 'none',
  Ir = 'ir',
  Loi = 'loi',
  Category = 'category',
  AllowRespondentIdentifiableInformation = 'allowRespondentIdentifiableInformation',
  AllowWebcamStudies = 'allowWebcamStudies',
}

export enum ProjectStatus {
  Active = 'active',
  Edit = 'edit',
  Closed = 'closed',
}

export enum ProjectListItemStatus {
  Active = 'active',
  Edit = 'edit',
  Closed = 'closed',
  Complete = 'complete',
  Draft = 'draft',
}

export enum AlarmType {
  LengthOfInterview = 'lengthOfInterview',
  IncidenceRate = 'incidenceRate',
  DropOutRate = 'dropOutRate',
  ConversionRate = 'conversionRate',
  QuotaFull = 'quotaFull',
}

export enum AggregatedRespondentBucketStatus {
  NotStarted = 'notStarted',
  Active = 'active',
  Closed = 'closed',
  Paused = 'paused',
}

export enum SortField {
  StartDate = 'startDate',
  CreatedDate = 'createdDate',
  UpdatedDate = 'updatedDate',
  Name = 'name',
  Id = 'id',
  ProjectActivity = 'projectActivity',
  ProjectCompletionOpen = 'projectCompletionOpen',
  ProjectCompletion = 'projectCompletion',
  EarliestStartDateOpen = 'earliestStartDateOpen',
  EarliestEndDateOpen = 'earliestEndDateOpen',
  LastEndDate = 'lastEndDate',
  LastEndDateOpen = 'lastEndDateOpen',
  OnHoldCount = 'onHoldCount',
  OldestTgActivity = 'lastTgActivity',
  AutoCloseDate = 'autoCloseDate',
}

export enum ProjectGroupSortField {
  HitScore = 'hitScore',
  CreatedDate = 'createdDate',
  LastStatisticsChange = 'lastStatisticsChange',
  Name = 'name',
  Id = 'id',
}

export enum DeviationFilter {
  OnHold = 'onHold',
  Stale = 'stale',
  Lagging = 'lagging',
}

export enum ProjectUserFilter {
  Company = 'company',
  ByContact = 'by-contact',
  CreatedBy = 'created-by',
  User = 'user',
}

export enum BackgroundDataJobStatus {
  Queued = 'Queued',
  Started = 'Started',
  Completed = 'Completed',
  Failed = 'Failed',
}

export enum ExternalIdsJobStatus {
  None = 'none',
  InProgress = 'inProgress',
  Succeeded = 'succeeded',
  Failed = 'failed',
}

export function availabilityGroupToEligiblePanelSourceKey(group: any): EligiblePanelSourceKeys | undefined {
  switch (group) {
    case SupplySource.OwnPanels:
    case SupplySource.PrivatePricing:
      return group as EligiblePanelSourceKeys;
    case 'opinionHub':
      return SupplySource.CintPanels as EligiblePanelSourceKeys;
    default:
      return undefined;
  }
}

export enum Availability {
  All = 'all',
  Global = 'global',
  Company = 'company',
}

export enum ProfilingScope {
  Global = 'global',
  PanelSpecific = 'panelSpecific',
}

export enum FeatureError {
  Alarms = 'alarms',
  AlarmLimits = 'alarmLimits',
  Statistics = 'statistics',
  TgActivityStatus = 'tgActivityStatus',
  Pricing = 'pricing',
}

/**
 * The underlying enum values are expressed in milliseconds, which makes it trivial to add or subtract the cache
 * duration from a datetime, e.g. `Date.now() + CacheDuration.Short` gives a timestamp 1 minute into the future.
 */
export enum CacheDuration {
  VeryShort = 30000, // 0.5 min
  Short = 60000, // 1 min
  Medium = 300000, // 5 min
  MediumLong = 900000, // 15 min
  Long = 1800000, // 30 min
  VeryLong = 3600000, // 60 min
  Extreme = 43200000, // 12 hours
}
