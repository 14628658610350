import { LogLevel } from '../error-logger';
import { ProjectListFilterOptions } from '../../project-list/project-filters/project-filters.component';
import { apiUrl } from '../../helpers';

export class UrlService {
  get projectListUrl() {
    return apiUrl`/services/projects/list`;
  }

  feasibility() {
    return apiUrl`/services/feasibility`;
  }

  infieldFeasibility(projectId: number, targetGroupId: number) {
    return apiUrl`/services/feasibility/infield/${projectId}/${targetGroupId}`;
  }

  getProject(id: number) {
    return apiUrl`/services/project/${id}`;
  }

  getProjectPrice(id: number, fetchInvoice: boolean) {
    return apiUrl`/services/project/${id}/price/${fetchInvoice}`;
  }

  postUpfrontPrice() {
    return apiUrl`/services/upfront-price`;
  }

  setEffectiveCpi(targetGroupId: number) {
    return apiUrl`/services/target-group/${targetGroupId}/effective-cpi`;
  }

  setEffectiveCpiForSupplyQuotas(targetGroupId: number) {
    return apiUrl`/services/target-group/${targetGroupId}/effective-cpi-quotas`;
  }

  getEffectiveCpiChanges(projectId: number, targetGroupId: number) {
    return apiUrl`/services/project/${projectId}/target-group/${targetGroupId}/effective-cpi`;
  }

  getEffectiveCpiChangesForSupplyMix(projectId: number, targetGroupId: number) {
    return apiUrl`/services/project/${projectId}/target-group/${targetGroupId}/effective-cpi-quotas`;
  }

  getCurrencies() {
    return apiUrl`/services/currencies`;
  }

  getBillingEntities() {
    return apiUrl`/services/billing-entities`;
  }

  getISOCurrencies() {
    return apiUrl`/services/iso-currencies`;
  }

  getCurrency(currencyCode: string) {
    return apiUrl`/services/currency/${currencyCode}`;
  }

  getQuotas(projectId: number) {
    return apiUrl`/services/project/${projectId}/target-group-quotas`;
  }

  getAggregatedStatus(projectId: number) {
    return apiUrl`/services/project/${projectId}/aggregated-status`;
  }

  updateQuotas(projectId: number) {
    return apiUrl`/services/project/${projectId}/update-quotas`;
  }

  updateOptimizer(projectId: number) {
    return apiUrl`/services/project/${projectId}/update-optimizer`;
  }

  updateBucketWantedQuantities(projectId: number) {
    return apiUrl`/services/project/${projectId}/update-bucket-wanted-quantities`;
  }

  relaxBuckets(projectId: number, targetGroupId: number) {
    return apiUrl`/services/project/${projectId}/target-group/${targetGroupId}/relax-buckets`;
  }

  createProject() {
    return apiUrl`/services/project`;
  }

  addTargetGroups(projectId: number) {
    return apiUrl`/services/project/${projectId}/add-target-groups`;
  }

  validateAddTargetGroups(projectId: number) {
    return apiUrl`/services/project/${projectId}/validate-add-target-groups`;
  }

  updateProject(id: number) {
    return apiUrl`/services/project/${id}/update`;
  }

  prepareProject(existingProjectId?: number) {
    return apiUrl`/services/project/prepare${existingProjectId !== undefined ? `/${existingProjectId}` : ''}`;
  }

  prepareProjectCompressed(existingProjectId?: number) {
    return apiUrl`/services/project/prepare/compressed${
      existingProjectId !== undefined ? `/${existingProjectId}` : ''
    }`;
  }

  postHttpsStatusValidation() {
    return apiUrl`/services/validation/httpsstatus`;
  }

  upsertQuotaPreset() {
    return apiUrl`/services/quota-presets/upsert`;
  }

  deleteQuotaPreset(presetId: number) {
    return apiUrl`/services/quota-presets/delete/${presetId}`;
  }

  getCountries() {
    return apiUrl`/services/countries`;
  }

  getCensus(countryId: number, minAge: number, maxAge: number) {
    return apiUrl`/services/country/${countryId}/census/${minAge}/${maxAge}`;
  }

  getCensusDeprecated(countryId: number, minAge: number, maxAge: number) {
    return apiUrl`/services/country/${countryId}/census-deprecated/${minAge}/${maxAge}`;
  }

  getRegionTypes(countryId: number) {
    return apiUrl`/services/country/${countryId}/region-types`;
  }

  getRegions(countryId: number, regionTypeId: number) {
    return apiUrl`/services/country/${countryId}/region-type/${regionTypeId}/regions`;
  }

  getRegionSearchResults(countryId: number, searchString: string) {
    return apiUrl`/services/country/${countryId}/region-search/${searchString}`;
  }

  postalCodes(countryId: number) {
    return apiUrl`/services/regions/upload-postal-codes/${countryId}`;
  }

  getCategoriesForCountry(countryId: number) {
    return apiUrl`/services/country/${countryId}/categories`;
  }

  searchCategories(countryId: number, categoryId: number, searchString: string) {
    return apiUrl`/services/country/${countryId}/categories/search?searchString=${searchString}&categoryId=${categoryId}`;
  }

  categoryIdByQuestionId(countryId: number, questionId: number, isPanelQuestion?: boolean) {
    return apiUrl`/services/country/${countryId}/category-id-for-question/${questionId}${
      isPanelQuestion ? '?isPanelQuestion=true' : ''
    }`;
  }

  getGlobalCategoriesByIds(countryId: number) {
    return apiUrl`/services/country/${countryId}/categories-ids`;
  }

  getPanelCategoriesByIds(countryId: number, panelId: number) {
    return apiUrl`/services/country/${countryId}/panel/${panelId}/categories-ids`;
  }

  getCategoriesForPanel(countryId: number, panelId: number) {
    return apiUrl`/services/country/${countryId}/panel/${panelId}/categories`;
  }

  getCountrySpecificCategory(countryId: number, categoryId: number) {
    return apiUrl`/services/country/${countryId}/categories/${categoryId}`;
  }

  getPanelCategory(countryId: number, panelId: number, categoryId: number) {
    return apiUrl`/services/country/${countryId}/panel/${panelId}/categories/${categoryId}`;
  }

  getCategoryDepth(categoryId: number) {
    return apiUrl`/services/depth/${categoryId}`;
  }

  getPanelInfo(panelId: number) {
    return apiUrl`/services/supply/panel/${panelId}`;
  }

  getPanelSuspensionLimits() {
    return apiUrl`/services/supply/panel/suspension-limits`;
  }

  generateRedirectLinks() {
    return apiUrl`/services/project/redirect-links`;
  }

  getRedirectLinksForProject(projectId: number) {
    return apiUrl`/services/project/${projectId}/redirect-links`;
  }

  reuseProject(projectId: number) {
    return apiUrl`/services/reuse-project/${projectId}`;
  }

  replaceProject(projectId: number) {
    return apiUrl`/services/replace/${projectId}`;
  }

  duplicateTargetGroup(projectId: number, targetGroupId: number, copyUrls: boolean) {
    return apiUrl`/services/project/${projectId}/duplicate-target-group/${targetGroupId}/${copyUrls}`;
  }

  getInfieldFeasibility(projectId: number, targetGroupId: number) {
    return apiUrl`/services/project/${projectId}/infield-feasibility/${targetGroupId}`;
  }

  getRespondentStatistics(projectId: number, targetGroupId: number) {
    return apiUrl`/services/project/${projectId}/target-group-stats/${targetGroupId}`;
  }

  getQuotaRespondedStats(projectId: number, targetGroupId: number, quotaName: string) {
    return apiUrl`/services/project/${projectId}/target-group/${targetGroupId}/quota-responded-stats/${encodeURIComponent(
      quotaName
    )}`;
  }

  getInvitationMailTemplate(projectId: number, targetGroupId: number) {
    return apiUrl`/services/project/${projectId}/target-group/${targetGroupId}/invitation-mail-template`;
  }

  updateTargetGroup(projectId: number, targetGroupId: number) {
    return apiUrl`/services/project/${projectId}/target-group/${targetGroupId}/update`;
  }

  previewInvitationMailTemplate(projectId: number) {
    return apiUrl`/services/project/${projectId}/target-group/preview-invitation-mail-template`;
  }

  createDraft() {
    return apiUrl`/services/draft`;
  }

  createDraftCompressed() {
    return apiUrl`/services/draft/compressed`;
  }

  createDraftFromPanelistPool() {
    return apiUrl`/services/panelist-draft`;
  }

  updateDraft(id: number) {
    return apiUrl`/services/draft/update/${id}`;
  }

  updateDraftCompressed(id: number) {
    return apiUrl`/services/draft/update/compressed/${id}`;
  }

  getAllDrafts() {
    return apiUrl`/services/drafts`;
  }

  deleteDraft(id: number) {
    return apiUrl`/services/draft/${id}`;
  }

  loadDraft(id: number) {
    return apiUrl`/services/draft/load/${id}`;
  }

  createTemplate() {
    return apiUrl`/services/target-group-template`;
  }

  updateTemplate(id: number) {
    return apiUrl`/services/target-group-template/update/${id}`;
  }

  getAllTemplates(countryId?: number) {
    const countryFilter = countryId ? `?countryId=${countryId}` : '';
    return apiUrl`/services/target-group-templates${countryFilter}`;
  }

  deleteTemplate(id: number) {
    return apiUrl`/services/target-group-template/${id}/`;
  }

  loadTemplate(id: number) {
    return apiUrl`/services/target-group-template/load/${id}`;
  }

  loadAndValidateTemplate(id: number) {
    return apiUrl`/services/target-group-template/load-and-validate/${id}`;
  }

  getTemplateCountries() {
    return apiUrl`/services/target-group-template/countries`;
  }

  getProjectListPage(index: number, pageSize: number, options: ProjectListFilterOptions, countryId?: number) {
    return [
      this.projectListUrl,
      `?status=${options.status}`,
      `&startIndex=${index}`,
      `&pageSize=${pageSize}`,
      `&sortField=${options.sortOptions.field}`,
      `&descending=${options.sortOptions.descending}`,
      `&projectOwnershipFiltering=${options.userFilter}`,
      !options.filterText ? '' : `&filterText=${options.filterText}`,
      options.fromDate === null ? '' : `&fromDate=${options.fromDate}`,
      options.toDate === null ? '' : `&toDate=${options.toDate}`,
      countryId ? `&countryId=${countryId}` : '',
    ].join('');
  }

  filterProjectListForExclusion(
    countryId: number,
    fromDate?: string,
    toDate?: string,
    textFilter?: string,
    idFilter?: string
  ) {
    return [
      apiUrl`/services/projects/projects-for-exclusion`,
      `?countryId=${countryId}`,
      textFilter ? `&textFilter=${encodeURIComponent(textFilter)}` : '',
      idFilter ? `&idFilter=${encodeURIComponent(idFilter)}` : '',
      fromDate && toDate ? `&fromDate=${encodeURIComponent(fromDate)}` : '',
      fromDate && toDate ? `&toDate=${encodeURIComponent(toDate)}` : '',
    ].join('');
  }

  paginateProjectListForExclusion(paginationToken: string) {
    return apiUrl`/services/projects/projects-for-exclusion?paginationToken=${paginationToken}`;
  }

  filterProjectListForExclusionPaginated(
    countryId: number,
    paginationToken?: string,
    fromDate?: string,
    toDate?: string,
    textFilter?: string,
    idFilter?: string
  ) {
    const parameters = [`countryId=${countryId}`];
    if (textFilter) parameters.push(`textFilter=${encodeURIComponent(textFilter)}`);
    if (paginationToken) parameters.push(`paginationToken=${paginationToken}`);
    if (idFilter) parameters.push(`idFilter=${encodeURIComponent(idFilter)}`);
    if (fromDate && toDate) {
      parameters.push(`fromDate=${encodeURIComponent(fromDate)}`);
      parameters.push(`toDate=${encodeURIComponent(toDate)}`);
    }
    return apiUrl`/services/projects/projects-for-exclusion?${parameters.join('&')}`;
  }

  getProjectNamesByIds() {
    return apiUrl`/services/projects/project-names`;
  }

  getProjectLiveData(projectId: number) {
    return apiUrl`/services/project/${projectId}/live-data`;
  }

  getProjectSamplingProgress(projectId: number) {
    return apiUrl`/services/project/${projectId}/sampling-progress`;
  }

  getProjectData(projectId: number) {
    return apiUrl`/services/project/${projectId}/project-data`;
  }

  basicProjectPanelistDataReport(projectId: number) {
    return apiUrl`/services/project/${projectId}/report/basic-panelist-data`;
  }

  extendedProjectPanelistDataReport(projectId: number) {
    return apiUrl`/services/project/${projectId}/report/extended-panelist-data`;
  }

  projectProgressReportStatus(projectId: number) {
    return apiUrl`/services/project/${projectId}/report/progress/status`;
  }

  projectProgressReport(projectId: number) {
    return apiUrl`/services/project/${projectId}/report/progress`;
  }

  draftSnapshotReport(draftId: number) {
    return apiUrl`/services/draft/${draftId}/report/snapshot`;
  }

  draftSnapshotReportStatus(draftId: number) {
    return apiUrl`/services/draft/${draftId}/report/snapshot/status`;
  }

  getDataReportStatus(projectId: number) {
    return apiUrl`/services/project/${projectId}/report/basic-panelist-data/status`;
  }

  getExtendedDataReportStatus(projectId: number) {
    return apiUrl`/services/project/${projectId}/report/extended-panelist-data/status`;
  }

  prepareRespondentStatusesUpdate(projectId: number) {
    return apiUrl`/services/project/${projectId}/update-respondent-statuses/prepare`;
  }

  confirmRespondentStatusesUpdate(projectId: number) {
    return apiUrl`/services/project/${projectId}/update-respondent-statuses/confirm`;
  }

  cancelRespondentStatusesUpdate(projectId: number) {
    return apiUrl`/services/project/${projectId}/update-respondent-statuses/cancel`;
  }

  closeProject(projectId: number) {
    return apiUrl`/services/project/${projectId}/close`;
  }

  updateLinkTemplate(projectId: number, targetGroupId: number) {
    return apiUrl`/services/project/${projectId}/target-group/${targetGroupId}/update-link-template`;
  }

  createWorkingDocument(projectId: number, targetGroupId: number) {
    return apiUrl`/services/project/${projectId}/create-working-document/${targetGroupId}`;
  }

  deleteWorkingDocument(projectId: number, workingDocumentId: string) {
    return apiUrl`/services/project/${projectId}/delete-working-document/${workingDocumentId}`;
  }

  uploadSurveyLinks(workingDocumentId: string, targetGroupId: number, linksRequired: number) {
    return apiUrl`/services/projects/upload-links/${workingDocumentId}/${targetGroupId}/${linksRequired}`;
  }

  addUploadedLinksToTargetGroup(projectId: number, workingDocumentId: string) {
    return apiUrl`/services/project/${projectId}/add-uploaded-links-to-target-group/${workingDocumentId}`;
  }

  calculateSendoutsForQuota(projectId: number, targetGroupId: number, quotaId: number) {
    return apiUrl`/services/project/${projectId}/target-group/${targetGroupId}/quota/${quotaId}/calculate-sendouts`;
  }

  sampleQuota(projectId: number, targetGroupId: number, quotaId: number) {
    return apiUrl`/services/project/${projectId}/target-group/${targetGroupId}/quota/${quotaId}/sample`;
  }

  calculateSendoutsForTargetGroup(projectId: number, targetGroupId: number) {
    return apiUrl`/services/project/${projectId}/target-group/${targetGroupId}/calculate-sendouts`;
  }

  sampleTargetGroup(projectId: number, targetGroupId: number) {
    return apiUrl`/services/project/${projectId}/target-group/${targetGroupId}/sample`;
  }

  closeQuotas(projectId: number) {
    return apiUrl`/services/project/${projectId}/close-quotas`;
  }

  reopenQuotas(projectId: number) {
    return apiUrl`/services/project/${projectId}/reopen-quotas`;
  }

  getActiveSendouts(projectId: number) {
    return apiUrl`/services/project/${projectId}/sendouts`;
  }

  getSendoutHistory(projectId: number) {
    return apiUrl`/services/project/${projectId}/sendouts/history`;
  }

  removeAllSendouts(projectId: number) {
    return apiUrl`/services/project/${projectId}/sendouts/remove`;
  }

  removeReminderSendouts(projectId: number) {
    return apiUrl`/services/project/${projectId}/sendouts/remove-reminder`;
  }

  quotaSelectionDetails(projectId: number) {
    return apiUrl`/services/project/${projectId}/quota-selection`;
  }

  bucketDetails(projectId: number, targetGroupId: number) {
    return apiUrl`/services/project/${projectId}/target-group/${targetGroupId}/bucket-details`;
  }

  quotaHasProcessingSamplingItems(projectId: number, targetGroupId: number, quotaId: number) {
    return apiUrl`/services/project/${projectId}/target-group/${targetGroupId}/quota/${quotaId}/has-processing-sampling-items`;
  }

  targetGroupHasProcessingSamplingItems(projectId: number, targetGroupId: number) {
    return apiUrl`/services/project/${projectId}/target-group/${targetGroupId}/has-processing-sampling-items`;
  }

  rescheduleSendouts(projectId: number) {
    return apiUrl`/services/project/${projectId}/sendouts/reschedule`;
  }

  checkPanelistAvailability() {
    return apiUrl`/services/supply/panelist-availability`;
  }

  getSupply(countryId: number) {
    return apiUrl`/services/country/${countryId}/supply`;
  }

  getSpecificPanels() {
    return apiUrl`/services/supply/specific-panels`;
  }

  panelistPoolUrl() {
    return apiUrl`/services/panelist-pool/url`;
  }

  panelistPoolEntries() {
    return apiUrl`/services/panelist-pool/entries`;
  }
  panelistPoolExternalIdJob() {
    return apiUrl`/services/panelist-pool/external-ids`;
  }

  externalIdsEntries() {
    return apiUrl`/services/external-ids`;
  }

  externalIdsJobStatus(id: string) {
    return apiUrl`/services/external-ids/status/${id}`;
  }

  getAdHocSupplierLanguages() {
    return apiUrl`/services/supply/suppliers/languages`;
  }

  getAdHocSuppliers() {
    return apiUrl`/services/supply/suppliers`;
  }

  createAdHocSupplier() {
    return apiUrl`/services/supply/supplier`;
  }

  updateAdHocSupplier(id: number) {
    return apiUrl`/services/supply/supplier/${id}`;
  }

  getProjectCategories() {
    return apiUrl`/services/project-categories`;
  }

  getBillingInformation(projectId: number) {
    return apiUrl`/services/company/billing-information/${projectId}`;
  }

  getRateCard() {
    return apiUrl`/services/company-rate-card`;
  }

  getInternalRateCard() {
    return apiUrl`/services/internal-rate-card`;
  }

  getTargetGroupRateCard() {
    return apiUrl`/services/target-group-rate-card`;
  }

  clientLogger(level: LogLevel) {
    return apiUrl`/client-log/${level}`;
  }

  getProjectLifecycleHistory(projectId: number) {
    return apiUrl`/services/project/${projectId}/history`;
  }

  getProjectHistoryFull(projectId: number) {
    return apiUrl`/services/project/${projectId}/history-full`;
  }

  getAllFeatures() {
    return apiUrl`/features`;
  }

  enableFeatures() {
    return apiUrl`/features/enable`;
  }

  clearCache(application: string) {
    return apiUrl`/features/cache/clear/${application}`;
  }

  clearCacheSection() {
    return apiUrl`/features/cache/clear-section`;
  }

  clearCacheItem() {
    return apiUrl`/features/cache/clear-item`;
  }

  getCacheKeys() {
    return apiUrl`/features/cache-keys`;
  }

  getAutoDelivery(projectId: number, targetGroupId: number) {
    return apiUrl`/services/project/${projectId}/target-group/${targetGroupId}/auto-delivery`;
  }

  getAutoDeliveryStatuses(projectId: number) {
    return apiUrl`/services/project/${projectId}/autodelivery-statuses`;
  }

  enableAutoDelivery(projectId: number, targetGroupId: number) {
    return apiUrl`/services/project/${projectId}/target-group/${targetGroupId}/auto-delivery-enable`;
  }

  disableAutoDelivery(projectId: number, targetGroupId: number) {
    return apiUrl`/services/project/${projectId}/target-group/${targetGroupId}/auto-delivery-disable`;
  }

  getTentativeAutoDeliverySchedule(projectId: number, targetGroupId: number): string {
    return apiUrl`/services/project/${projectId}/target-group/${targetGroupId}/auto-delivery-tentative-schedule`;
  }

  getSamplingActivity(projectId: number, targetGroupId: number) {
    return apiUrl`/services/project/${projectId}/target-group/${targetGroupId}/sampling-activity`;
  }

  getSamplingRequirementStatus(projectId: number, targetGroupId: number) {
    return apiUrl`/services/project/${projectId}/target-group/${targetGroupId}/sampling-requirement-status`;
  }

  updateTargetGroupCustomParameters(projectId: number, targetGroupId: number) {
    return apiUrl`/services/project/${projectId}/target-group/${targetGroupId}/update-target-group-parameters`;
  }

  targetGroupParametersSynced(projectId: number, targetGroupId: number) {
    return apiUrl`/services/project/${projectId}/target-group/${targetGroupId}/target-group-parameters-synced`;
  }

  projectParametersSynced(projectId: number) {
    return apiUrl`/services/project/${projectId}/project-parameters-synced`;
  }

  getProjectCustomParameters(projectId: number) {
    return apiUrl`/services/project/${projectId}/project-parameters`;
  }

  updateProjectCustomParameters(projectId: number) {
    return apiUrl`/services/project/${projectId}/update-project-parameters`;
  }

  searchProjects(projectIdQuery: number) {
    return apiUrl`/features/projects/search/${projectIdQuery}`;
  }

  exportProject(id: number | undefined) {
    return apiUrl`/features/projects/export/${id}`;
  }

  importProject() {
    return apiUrl`/features/projects/import`;
  }

  recreateProject(projectId: number) {
    return apiUrl`/features/project/${projectId}/recreate`;
  }

  contactNames(query: string) {
    return apiUrl`/services/company/contact-names?query=${encodeURIComponent(query)}`;
  }

  projectParametersDefinition() {
    return apiUrl`/services/company/project-parameters-definition`;
  }

  targetGroupParametersDefinition() {
    return apiUrl`/services/company/target-group-parameters-definition`;
  }

  getCompanySurveyMetadataTags() {
    return apiUrl`/services/company/survey-metadata`;
  }

  getTargetGroupSurveyMetadata(projectId: number, targetGroupId: number) {
    return apiUrl`/services/project/${projectId}/target-group/${targetGroupId}/survey-metadata`;
  }

  getExistingTargetGroup(projectId: number, targetGroupId: number) {
    return apiUrl`/services/project/${projectId}/target-group/${targetGroupId}`;
  }

  getProjectProgressReport(projectId: number) {
    return apiUrl`/services/project/${projectId}/report/progress`;
  }

  getSuspensionLimits(projectId: number, targetGroupId: number) {
    return apiUrl`/services/project/${projectId}/target-group/${targetGroupId}/suspension-limits`;
  }

  setSuspensionLimits(projectId: number, targetGroupId: number) {
    return apiUrl`/services/project/${projectId}/target-group/${targetGroupId}/suspension-limits`;
  }

  getActiveProjects() {
    return apiUrl`/services/projects/active`;
  }

  getUnclosedProjects() {
    return apiUrl`/services/projects/unclosed`;
  }

  getAutoClosingProjects() {
    return apiUrl`/services/projects/autoclosing`;
  }

  projectInactivityThreshold(projectId: number) {
    return apiUrl`/services/project/${projectId}/auto-close/inactivity-threshold`;
  }

  getProjectAutoCloseDate(projectId: number) {
    return apiUrl`/services/project/${projectId}/auto-close/date`;
  }

  getDeviatingProjects() {
    return apiUrl`/services/projects/deviating`;
  }

  getGroupProjects() {
    return apiUrl`/services/projects/group`;
  }

  searchProjectsV2() {
    return apiUrl`/services/projects/search`;
  }

  searchProjectGroups() {
    return apiUrl`/services/project-groups/search`;
  }

  getPanelistPools(paginationToken = '', searchString = '') {
    return apiUrl`/services/panelist-pool/list?paginationToken=${paginationToken}&searchString=${searchString}`;
  }

  getTargetGroupList(projectId: number, deviatingOnly: boolean) {
    return apiUrl`/services/projects/${projectId}/target-groups-list?deviatingOnly=${deviatingOnly}`;
  }

  getQuotaPresets() {
    return apiUrl`/services/quota-presets/list`;
  }

  clearCountryCensusCache(countryId: number, global: boolean) {
    return apiUrl`/services/quota-presets/clear-country-census-cache?countryId=${countryId}${
      global ? '&global=true' : ''
    }`;
  }

  getSetProjectDescription(projectId: number) {
    return apiUrl`/services/project/${projectId}/description`;
  }

  getSetProjectGroupsForProject(projectId: number) {
    return apiUrl`/services/project-groups/project/${projectId}`;
  }

  getProjectGroupsForCompany() {
    return apiUrl`/services/project-groups`;
  }

  createProjectGroup() {
    return apiUrl`/services/project-groups/create`;
  }

  deleteProjectGroup(id: string) {
    return apiUrl`/services/project-groups/${encodeURI(id)}`;
  }

  getAsapDateString() {
    return apiUrl`/services/settings/asap-date-string`;
  }

  approveLaunchTargetGroup(projectId: number, targetGroupId: number) {
    return apiUrl`/services/project/${projectId}/target-group/${targetGroupId}/approve-launch`;
  }

  approveAllTargetGroupsForLaunch(projectId: number) {
    return apiUrl`/services/project/${projectId}/approve-launch`;
  }
}

export const urlService = new UrlService();
